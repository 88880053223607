<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title mt-4">
              <i class="fa-solid fa-table-list" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="gpathRoadmapAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="col-7 col-sm-4 col-md-3 col-lg-3 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
              <b-col class="col-5 col-sm-3 col-md-2 col-lg-3 mb-2">
                <VueJsonToCsv :json-data="gpathRoadmapObjList" csv-title="gpath_roadmap" v-if="gpathRoadmapObjList && gpathRoadmapObjList.length">
                  <b-btn variant="primary" class="Download_button">CSV</b-btn>
                </VueJsonToCsv>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpathRoadmapObjList && gpathRoadmapObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpathRoadmapObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(name)="data">
                    {{ data.item.name }}<br>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="gpathRoadmapEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showGpathRoadmapDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGpathRoadmapAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl"
    >
      <GpathRoadmapAdd :propOpenedInModal="true" @emitCloseGpathRoadmapAddModal="closeGpathRoadmapAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpathRoadmapAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGpathRoadmapAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGpathRoadmapEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl"
    >
      <GpathRoadmapEdit :propOpenedInModal="true" :propGpathRoadmapObj="gpathRoadmapEditObj" @emitCloseGpathRoadmapEditModal="closeGpathRoadmapEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpathRoadmapEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGpathRoadmapEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGpathRoadmapDelete" size="xl" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGpathRoadmapDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpathRoadmapDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { GpathRoadmaps } from "../../../FackApi/api/GpathRoadmap"
import GpathRoadmapAdd from "./GpathRoadmapAdd"
import GpathRoadmapEdit from "./GpathRoadmapEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "GpathRoadmapList",
  components: {
    GpathRoadmapAdd,
    GpathRoadmapEdit,
    CsvDownload,
    CsvUpload
  },
  data () {
    return {
      apiName: "gpath_roadmap_list",
      cvModuleFile: "gpath_roadmap",
      cvModulePrefix: "GPR",
      cvCardTitle: "GPaths Roadmaps",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GpathRoadmap",
      cvAddModalHeader: "Add GpathRoadmap",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GpathRoadmap List Response",
      showModelGpathRoadmapAdd: false,
      showModelGpathRoadmapEdit: false,
      showModelGpathRoadmapDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "name", key: "name", class: "text-left align-text-top", sortable: true },
        { label: "header description", key: "header_description", class: "text-left align-text-top", sortable: true },
        { label: "footer description", key: "footer_description", class: "text-left align-text-top", sortable: true },
        { label: "Highlight data", key: "highlightdata", class: "text-left align-text-top", sortable: true },
        { label: "highlight Title", key: "highlightTitle", class: "text-left align-text-top", sortable: true },
        { label: "mark", key: "mark", class: "text-left align-text-top", sortable: true },
        { label: "courses_css", key: "courses_css", class: "text-left align-text-top", sortable: true },
        { label: "detail", key: "detail", class: "text-left align-text-top", sortable: true },
        { label: "subHeading", key: "subHeading", class: "text-left align-text-top", sortable: true },

        { label: "detail", key: "detail", class: "text-left align-text-top", sortable: true }
      ],
      gpathRoadmapObjList: null,
      gpathRoadmapEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.gpathRoadmapList()
  },
  methods: {
    /**
     * gpathRoadmapList
     */
    async gpathRoadmapList () {
      try {
        let gpathRoadmapListResp = await GpathRoadmaps.gpathRoadmapList(this)
        if (gpathRoadmapListResp.resp_status) {
          this.gpathRoadmapObjList = gpathRoadmapListResp.resp_data.data
          this.totalRows = gpathRoadmapListResp.resp_data.count
        }
        else {
          this.toastMsg = gpathRoadmapListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpathRoadmapList() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapAdd
     */
    gpathRoadmapAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpathRoadmap_add")
        }
        else {
          this.showModelGpathRoadmapAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapAdd() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapEdit
     */
    gpathRoadmapEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpathRoadmap_edit/" + this.gpathRoadmapEditObj.grmap_id)
        }
        else {
          this.gpathRoadmapEditObj = item
          this.showModelGpathRoadmapEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapEdit() and Exception:", err.message)
      }
    },
    /**
     * showGpathRoadmapDeleteDialog
     */
    showGpathRoadmapDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGpathRoadmapDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGpathRoadmapDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapDelete
     */
    async gpathRoadmapDelete () {
      try {
        let gpathRoadmapDelResp = await GpathRoadmaps.gpathRoadmapDelete(this, this.delObj.grmap_id)
        await ApiResponse.responseMessageDisplay(this, gpathRoadmapDelResp)

        if (gpathRoadmapDelResp && !gpathRoadmapDelResp) {
          this.showModelGpathRoadmapDelete = false
          return false
        }

        let index = this.gpathRoadmapObjList.indexOf(this.delObj)
        this.gpathRoadmapObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGpathRoadmapDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGpathRoadmapAddModal
     */
    closeGpathRoadmapAddModal (gpathRoadmapAddData) {
      try {
        if (gpathRoadmapAddData) {
          if (this.gpathRoadmapObjList && this.gpathRoadmapObjList.length >= 1) {
            let gpathRoadmapObjLength = this.gpathRoadmapObjList.length
            let lastId = this.gpathRoadmapObjList[gpathRoadmapObjLength - 1]["id"]
            gpathRoadmapAddData.id = lastId + 1
          }
          else {
            this.gpathRoadmapObjList = []
            gpathRoadmapAddData.id = 11111
          }

          gpathRoadmapAddData.created_on = moment()
          this.gpathRoadmapObjList.unshift(gpathRoadmapAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGpathRoadmapAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpathRoadmapAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGpathRoadmapEditModal
     */
    closeGpathRoadmapEditModal () {
      try {
        this.showModelGpathRoadmapEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGpathRoadmapEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
